import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"

/* eslint-disable jsx-a11y/accessible-emoji */

const SiteFooter = styled.footer`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: #E5F5FB;
    justify-content: center;
    margin: auto;
    display: ${({ styleData }) => styleData && styleData.hideContactInfo ? 'none' : 'block'};
`;

const FooterDiv = styled.footer `
    width: 100%;
    max-width: 1140px;
    position: relative;
    display: flex;
    align-items: top;
    justify-content: center;
    flex-wrap: wrap;
    padding: 100px 0;
    margin: auto;

    #linkedin, #twitter {
        width: 40px;
        height: 40px;
    }

    ul {
        width: 100%;
        margin: 0;
        transition: all .25s ease-out;
        font-weight: 500;
        list-style-type:none;
    }

    li {
        list-style: none;
        margin-bottom: 10px;
        text-transform: uppercase;

        a {
            font-size: 18px;
        }

        &.kid {
            text-transform: initial;
            a {
                font-weight: 400;
            }
        }

        &:last-of-type {
            padding-right: 0;
        }
    }

    .column {
        &:first-of-type {
            margin-left: 15px;
            margin-right: 80px;

            p {
                font-size: 18px;
            }

            a {
                margin-right: 20px;
            }

            small {
                display: inline-block;
                font-size: 12px;
                font-weight: 500;
                padding-top: 50px;
            }
        }

        &.medium-only {
            display: none;
        }
    }

    @media only screen and (max-width: 1140px) {
        padding: 40px 0;

        .column {
            display: none;
            max-width: 80%;

            &:first-of-type {
                display: block;
                margin: 0;
                padding-left: 30px;

                > div {
                    display: none;
                }

                p {
                    margin-bottom: 0;
                }
            }

            &.medium-only {
                display: block;
                padding-right: 30px;
                text-align: right;

                a {
                    margin-right: 30px;
                }
            }
        }

        @media only screen and (max-width: 640px) {
            padding: 60px 0;

            .column {
                display: none;
                max-width: 100%;

                &:first-of-type {
                    padding-left: 0;

                    > * {
                        width: 100%;
                    }

                    p a {
                        margin-right: 0;
                    }

                    p, div {
                        text-align: center;
                    }

                    div {
                        margin-top: 30px;
                        display: block;
                    }

                    svg {
                        margin-bottom: 30px;
                    }

                    a {
                        padding: 10px 0;

                        > svg {
                            width: 60px;
                            height: 60px;
                            margin-bottom: 0;
                            margin-top: 10px;
                        }

                        #linkedin {
                            margin-right: 20px;
                        }

                        #twitter {
                            margin-left: 20px;
                        }
                    }
                }

                &.medium-only {
                    display: none;
                }
            }
    }
`;

const SubFooterContent = styled.div `
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 100px 0 80px;

    svg {
        padding-right: 25px;
    }

    div {
        max-width: 707px;
    }

    p {
        font-size: 18px;
        line-height: 30px;
        font-weight: 500;
    }

    @media only screen and (max-width: 1140px) {
        max-width: 600px;
        margin:auto;
        padding-bottom: 92px;

        svg {
            width: 100%;
            padding-bottom: 10px;
            padding-right: 0;
        }

        div, p, h2 {
            text-align: center;
            max-width: 375px;
        }

        p {
            max-width: 80%;
            margin: auto;
        }

        h2 {
            margin-bottom: 40px;
        }

    }
`;

const ContactUsButton = styled(Link)`
    background: var(--buttons);
    border: 1px solid var(--buttons);
    border-radius: 2px;
    width: 260px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    color: var(--white);
    float: right;
    margin-top: -15px;
    cursor: pointer;
    margin-bottom: 0;
    padding: 0;
    height: 60px;
    line-height: 60px;
    margin-left: 30px;

    &:focus, &:hover {
        outline: none;
        background: #23C0F5;
        border-color: #23C0F5;
    }

    &:active {
        background: #16A0CF;
        border-color: #16A0CF;
        }
    }

    @media only screen and (max-width: 1140px) {
        margin-top: 60px;
        margin-left: 0;
    }
`

const StyledMenuLink = styled(Link)`
`;

const contactSVG = () => (
    <svg width="95px" height="95px" viewBox="0 0 95 95" version="1.1">
        <title>Contact Icon</title>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="GET-IN-TOUCH" transform="translate(-165.000000, -105.000000)">
                <rect id="Rectangle-Copy" fillOpacity="0.1" fill="none" x="0" y="0" width="1440" height="300"></rect>
                <g id="Group" transform="translate(165.000000, 105.000000)" fillRule="nonzero">
                    <g id="Contact-US---Icon">
                        <path d="M78.3534091,78.1590909 L78.3534091,72.9017045 C78.3534091,71.9358338 77.5704162,71.1528409 76.6045455,71.1528409 C75.6386747,71.1528409 74.8556818,71.9358338 74.8556818,72.9017045 L74.9744318,82.3693182 C74.9727318,82.8329194 75.1598143,83.2772406 75.4926136,83.6 C75.8105479,83.9255267 76.2466787,84.1085459 76.7017045,84.1073918 L76.7017045,84.1073918 L86.1693182,83.9886364 C87.130209,83.9651931 87.8968768,83.1793586 87.8965909,82.2181818 C87.8847699,81.2607534 87.1052287,80.4908361 86.1477273,80.4909091 L86.1477273,80.4909091 L80.8903409,80.4909091 L81.1386364,80.2210227 C93.1126338,67.9486949 97.4521565,50.1289139 92.4614083,33.7252839 C87.47066,17.3216539 73.9419162,4.93823188 57.1619318,1.41420455 C48.6284393,-0.348351075 39.7737563,0.283058811 31.5767045,3.23863636 C30.8106569,3.64290953 30.4591981,4.54952278 30.7525897,5.36449952 C31.0459813,6.17947626 31.8946635,6.65403876 32.7426136,6.47727273 C51.8378467,-0.422106887 73.1661819,6.80743781 84.1303935,23.8958968 C95.0946051,40.9843558 92.7775372,63.3851457 78.5477273,77.8676136 L78.3534091,78.1590909 Z" id="Path" fill="#33BBA1"></path>
                        <path d="M63.4232955,91.7613636 C64.0882976,91.6227355 64.6115929,91.1093935 64.7629644,90.4471758 C64.9143359,89.7849581 64.6660387,89.0952434 64.1273076,88.6814626 C63.5885766,88.2676818 62.8581567,88.2056774 62.2573864,88.5227273 C43.1460831,95.4658938 21.7744424,88.2452378 10.7902153,71.1339483 C-0.194011855,54.0226588 2.13921511,31.5851689 16.4090909,17.1 L16.6681818,16.8301136 L16.6681818,22.0982955 C16.6800028,23.0557239 17.4595441,23.8256412 18.4170455,23.8255682 L18.4170455,23.8255682 C19.3779363,23.8021249 20.1446041,23.0162904 20.1443182,22.0551136 L20.0255682,12.5875 C20.0149663,11.6587545 19.2802139,10.9003004 18.3522727,10.8602273 L18.3522727,10.8602273 L8.88465909,10.9681818 C7.92376823,10.9916251 7.15710043,11.7774596 7.15738636,12.7386364 C7.16920737,13.6960648 7.94874862,14.4659821 8.90625,14.4659091 L8.90625,14.4659091 L14.1636364,14.4659091 L13.9153409,14.7357955 C0.754762897,28.2643063 -3.03741958,48.35276 4.28554428,65.7480358 C11.6085081,83.1433116 28.6262197,94.4713349 47.5,94.5142045 C52.9251283,94.5022841 58.309046,93.5715051 63.4232955,91.7613636 Z" id="Path" fill="#33BBA1"></path>
                        <path d="M24.2357955,67.5795455 C23.1941993,68.0905634 22.6048946,69.2185859 22.7803825,70.3654363 C22.9558704,71.5122867 23.855526,72.4124726 25.0022727,72.5886364 C25.6057662,72.6823879 26.2160189,72.7257195 26.8267045,72.7181818 C30.0465651,72.6667222 33.1806368,71.6720767 35.8409091,69.8573864 C47.3540512,75.9052262 61.5762669,72.2588199 68.7592354,61.4175206 C75.9422039,50.5762212 73.7552092,36.0577933 63.697281,27.8135899 C53.6393529,19.5693865 38.974069,20.2744713 29.7534418,29.4455587 C20.5328147,38.616646 19.7487361,53.2779187 27.9386364,63.3801136 C27.186558,65.1407429 25.8884221,66.6129729 24.2357955,67.5795455 Z M32.2028409,31.9545455 C40.234679,23.9370845 53.0658484,23.398829 61.7409368,30.71545 C70.4160253,38.032071 72.0498343,50.7701751 65.5020599,60.0393041 C58.9542855,69.3084331 46.4026149,72.0258201 36.6073864,66.2948864 C36.3383448,66.1379101 36.0335926,66.0524308 35.7221591,66.0465909 C35.3307197,66.0405908 34.9492728,66.1702827 34.6426136,66.4136364 C32.7191268,67.9268563 30.413175,68.876146 27.9818182,69.1556818 C29.5420565,67.6256926 30.766372,65.787381 31.5767045,63.7579545 C31.8362778,63.1509283 31.731893,62.4494623 31.3068182,61.9443182 C23.6578818,53.2850209 24.0500432,40.1736006 32.2028409,31.9869318 L32.2028409,31.9545455 Z" id="Shape" fill="#3D7BB2"></path>
                        <path d="M47.5,49.2488636 C48.469425,49.2489357 49.2586323,48.4693529 49.2704545,47.5 C49.2673027,47.0373825 49.0809777,46.5948607 48.7522727,46.2693182 C48.4187735,45.9393247 47.9691619,45.7532784 47.5,45.7511364 C46.5474376,45.774309 45.7840655,46.5472232 45.7727273,47.5 C45.7804589,47.9616645 45.9660862,48.4025294 46.2909091,48.7306818 C46.6111452,49.0541777 47.0448919,49.2400691 47.5,49.2488636 Z" id="Path" fill="#33BBA1"></path>
                        <path d="M53.9772727,49.2488636 C54.9419705,49.2375185 55.7245466,48.464486 55.7477273,47.5 C55.7453572,47.0350609 55.554477,46.5909723 55.21875,46.2693182 C54.897444,45.9375385 54.4553266,45.7502405 53.9934659,45.7502405 C53.5316053,45.7502405 53.0894878,45.9375385 52.7681818,46.2693182 C52.4353825,46.5920776 52.2482999,47.0363987 52.2499885,47.5 C52.2519653,47.9628605 52.4384738,48.4058182 52.7681818,48.7306818 C53.0909527,49.0504345 53.5231271,49.2356521 53.9772727,49.2488636 Z" id="Path" fill="#33BBA1"></path>
                        <path d="M41.0227273,49.2488636 C41.4853448,49.2457118 41.9278666,49.0593868 42.2534091,48.7306818 C42.586159,48.4060013 42.7797064,47.9647132 42.7931818,47.5 C42.7931818,46.5341293 42.0101889,45.7511364 41.0443182,45.7511364 C40.0784475,45.7511364 39.2954545,46.5341293 39.2954545,47.5 C39.3031862,47.9616645 39.4888135,48.4025294 39.8136364,48.7306818 C40.1325435,49.0561246 40.5671258,49.2423742 41.0227273,49.2488636 Z" id="Path" fill="#33BBA1"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const logoSVG = () => (
    <svg width="300" height="39" viewBox="0 0 300 39" fill="none">
    <g clipPath="url(#clip0_189_12682)">
    <path d="M48.8584 8.83887H54.2859V27.8661H66.0437V32.2856H48.869V8.83887H48.8584Z" fill="#3C7BB1"/>
    <path d="M85.8522 27.2606H74.9654L72.8836 32.2856H67.3286L77.78 8.83887H83.1332L93.6165 32.2856H87.9234L85.8522 27.2606ZM84.1422 23.1385L80.4247 14.1614L76.7073 23.1385H84.1422Z" fill="#3C7BB1"/>
    <path d="M94.7319 8.83887H105.385C107.934 8.83887 110.175 9.32756 112.13 10.2943C114.084 11.2611 115.603 12.6316 116.686 14.3951C117.77 16.1587 118.311 18.2091 118.311 20.5569C118.311 22.9048 117.77 24.9552 116.686 26.7187C115.603 28.4823 114.084 29.8528 112.13 30.8195C110.175 31.7863 107.924 32.275 105.385 32.275H94.7319V8.83887ZM105.109 27.8236C107.456 27.8236 109.326 27.1756 110.717 25.8688C112.108 24.5621 112.809 22.7879 112.809 20.5569C112.809 18.3259 112.108 16.5518 110.717 15.245C109.326 13.9383 107.456 13.2902 105.109 13.2902H100.149V27.8236H105.109Z" fill="#3C7BB1"/>
    <path d="M120.382 8.83887H131.036C133.585 8.83887 135.826 9.32756 137.78 10.2943C139.734 11.2611 141.253 12.6316 142.337 14.3951C143.42 16.1587 143.962 18.2091 143.962 20.5569C143.962 22.9048 143.42 24.9552 142.337 26.7187C141.253 28.4823 139.734 29.8528 137.78 30.8195C135.826 31.7863 133.574 32.275 131.036 32.275H120.382V8.83887ZM130.759 27.8236C133.107 27.8236 134.976 27.1756 136.367 25.8688C137.759 24.5621 138.46 22.7879 138.46 20.5569C138.46 18.3259 137.759 16.5518 136.367 15.245C134.976 13.9383 133.107 13.2902 130.759 13.2902H125.799V27.8236H130.759Z" fill="#3C7BB1"/>
    <path d="M156.144 9.47632C158.152 9.47632 159.798 9.84815 161.296 11.0168C162.931 12.2598 163.909 14.1933 163.909 16.743C163.909 19.2927 162.836 21.3644 161.094 22.6392C159.49 23.776 157.578 24.0097 155.635 24.0097H148.805V32.0413C148.805 32.1794 148.709 32.275 148.571 32.275H147.435C147.297 32.275 147.201 32.1794 147.201 32.0413V9.71004C147.201 9.57193 147.307 9.47632 147.435 9.47632H156.144ZM160.085 12.0898C158.885 11.2186 157.536 10.8893 155.836 10.8893H148.805V22.6073H155.369C157.005 22.6073 158.651 22.4374 159.926 21.5662C161.402 20.5569 162.305 18.8253 162.305 16.743C162.305 14.6607 161.466 13.0565 160.095 12.0898H160.085Z" fill="#959FA7"/>
    <path d="M163.675 32.0413C163.611 32.1794 163.505 32.275 163.335 32.275H162.23C162.092 32.275 161.997 32.2113 161.997 32.1051C161.997 32.0413 161.997 31.967 162.06 31.8395L171.195 9.66758C171.258 9.43386 171.365 9.37012 171.566 9.37012H172.735C172.937 9.37012 173.032 9.43386 173.107 9.66758L182.241 31.8076C182.273 31.9032 182.336 32.0413 182.336 32.1051C182.336 32.2007 182.241 32.275 182.103 32.275H180.934C180.733 32.275 180.637 32.1794 180.595 32.0413L178.152 26.1451H166.065L163.654 32.0413H163.675ZM172.151 11.3461L166.564 24.8384H177.748L172.161 11.3461H172.151Z" fill="#959FA7"/>
    <path d="M194.477 22.8729L201.646 31.8819C201.784 32.0838 201.784 32.2856 201.508 32.2856H200.234C199.968 32.2856 199.905 32.2538 199.766 32.0519L192.565 22.9154H186.076V32.0519C186.076 32.19 185.98 32.2856 185.842 32.2856H184.705C184.567 32.2856 184.472 32.19 184.472 32.0519V9.71004C184.472 9.57193 184.578 9.47632 184.705 9.47632H193.372C195.55 9.47632 197.26 9.67817 198.832 10.6874C200.467 11.6967 201.54 13.4709 201.54 16.2118C201.54 18.9528 200.436 20.5676 198.832 21.6406C197.525 22.5117 196.251 22.8092 194.477 22.8836V22.8729ZM186.076 10.8468V21.5343H192.937C194.806 21.5343 196.314 21.4387 197.589 20.6951C198.927 19.8877 199.905 18.5172 199.905 16.2118C199.905 13.9064 198.97 12.4935 197.663 11.7286C196.357 10.9955 194.881 10.8574 193.075 10.8574H186.076V10.8468Z" fill="#959FA7"/>
    <path d="M220.159 9.47632C220.297 9.47632 220.393 9.57193 220.393 9.71004V10.6768C220.393 10.7724 220.297 10.9105 220.159 10.9105H212.087V32.0413C212.087 32.1794 211.991 32.275 211.885 32.275H210.717C210.621 32.275 210.483 32.1794 210.483 32.0413V10.9105H202.453C202.315 10.9105 202.22 10.7724 202.22 10.6768V9.71004C202.22 9.57193 202.315 9.47632 202.453 9.47632H220.159Z" fill="#959FA7"/>
    <path d="M240.988 9.71004C240.988 9.57193 241.126 9.47632 241.221 9.47632H242.326C242.464 9.47632 242.56 9.57193 242.56 9.71004V32.0413C242.56 32.1794 242.464 32.275 242.326 32.275H241.189C240.988 32.275 240.892 32.2431 240.722 32.0413L224.652 12.0473V32.0413C224.652 32.1794 224.514 32.275 224.418 32.275H223.314C223.176 32.275 223.08 32.1794 223.08 32.0413V9.71004C223.08 9.57193 223.186 9.47632 223.314 9.47632H224.418C224.62 9.47632 224.716 9.54006 224.854 9.74191L240.988 29.7678V9.71004Z" fill="#959FA7"/>
    <path d="M262.124 9.47632C262.262 9.47632 262.358 9.57193 262.358 9.71004V10.6768C262.358 10.7724 262.262 10.9105 262.124 10.9105H248.061V20.1532H260.414C260.52 20.1532 260.648 20.2914 260.648 20.387V21.3537C260.648 21.4918 260.51 21.5875 260.414 21.5875H248.061V30.862H262.124C262.262 30.862 262.358 30.9577 262.358 31.0958V32.0307C262.358 32.1688 262.262 32.2644 262.124 32.2644H246.691C246.553 32.2644 246.458 32.1688 246.458 32.0307V9.71004C246.458 9.57193 246.564 9.47632 246.691 9.47632H262.124Z" fill="#959FA7"/>
    <path d="M275.454 22.8729L282.623 31.8819C282.762 32.0838 282.762 32.2856 282.485 32.2856H281.211C280.945 32.2856 280.882 32.2538 280.743 32.0519L273.542 22.9154H267.053V32.0519C267.053 32.19 266.957 32.2856 266.819 32.2856H265.682C265.544 32.2856 265.449 32.19 265.449 32.0519V9.71004C265.449 9.57193 265.555 9.47632 265.682 9.47632H274.349C276.527 9.47632 278.237 9.67817 279.809 10.6874C281.444 11.6967 282.517 13.4709 282.517 16.2118C282.517 18.9528 281.413 20.5676 279.809 21.6406C278.502 22.5117 277.228 22.8092 275.454 22.8836V22.8729ZM267.053 10.8468V21.5343H273.914C275.783 21.5343 277.292 21.4387 278.566 20.6951C279.904 19.8877 280.882 18.5172 280.882 16.2118C280.882 13.9064 279.947 12.4935 278.64 11.7286C277.334 10.9955 275.858 10.8574 274.052 10.8574H267.053V10.8468Z" fill="#959FA7"/>
    <path d="M284.759 30.4052C284.621 30.299 284.557 30.2034 284.621 30.0652L285.088 29.0985C285.152 28.9604 285.258 28.8966 285.417 29.0347C286.787 29.9378 288.901 31.2445 292.215 31.2445C294.02 31.2445 295.465 30.8089 296.463 30.0759C297.674 29.1728 298.269 27.8342 298.269 26.2194C298.269 24.2753 297.026 23.0748 295.359 22.2992C293.288 21.3325 291.078 21.0987 288.434 19.9514C286.32 19.0484 284.78 17.5716 284.78 15.0963C284.78 13.354 285.385 11.9198 286.554 10.9424C287.86 9.77377 289.867 9.16821 292.544 9.16821C294.955 9.16821 297.26 9.77377 298.842 10.7405C299.012 10.8043 299.044 10.9424 298.949 11.0805L298.513 12.0154C298.481 12.1535 298.343 12.1854 298.141 12.0791C296.431 11.0699 294.286 10.5706 292.48 10.5706C290.239 10.5706 288.593 11.1017 287.594 12.0473C286.819 12.7803 286.458 13.8214 286.458 15.0326C286.458 16.9448 287.796 18.0497 289.942 18.8784C292.353 19.7814 294.626 20.0151 296.878 21.1944C298.481 22.0655 299.99 23.606 299.99 26.0494C299.99 28.2592 299.151 29.9378 297.674 31.0426C296.336 32.0838 294.456 32.615 292.215 32.615C288.763 32.615 286.352 31.4463 284.748 30.4052H284.759Z" fill="#959FA7"/>
    <path d="M31.6089 34.7822C30.2919 33.348 28.1995 32.9336 26.4576 33.7835C20.2972 36.7794 12.6604 35.5471 7.79584 30.2352C2.93127 24.9339 2.34709 17.2104 5.86276 11.3354C6.86117 9.66749 6.6275 7.54273 5.31045 6.11914C-1.64654 13.4921 -1.8271 25.0933 5.14051 32.6787C12.0975 40.2641 23.6748 41.0821 31.6195 34.7715L31.6089 34.7822Z" fill="#3C7BB1"/>
    <path d="M15.7197 32.6469C16.973 33.0081 18.2795 33.2099 19.6496 33.2099C20.9029 33.2099 22.1244 33.0293 23.2821 32.7213V17.4336C20.9985 18.4322 18.5131 19.144 15.7197 19.6009V32.6469Z" fill="#3C7BB1"/>
    <path d="M6.66992 24.7853C8.15691 28.1 10.8654 30.7453 14.2323 32.137V20.0684C11.4177 20.4189 9.3465 20.7802 6.66992 20.6739V24.7853Z" fill="#3C7BB1"/>
    <path d="M31.3968 12.2598C29.9841 13.5559 27.0845 15.7338 24.769 16.8599V32.2431C28.0617 30.9683 30.7489 28.5142 32.3314 25.4014V13.5665C32.3314 13.5665 31.4817 12.366 31.3968 12.2598Z" fill="#3C7BB1"/>
    <path d="M26.8296 1.43422C20.4887 -1.13674 13.0325 -0.265592 7.39258 4.20703C8.70963 5.64125 10.802 6.05557 12.5439 5.20567C15.3692 3.82457 18.5025 3.35713 21.5402 3.75021L23.399 2.95342C24.2274 2.59221 25.4914 2.03977 26.8296 1.43422Z" fill="#959FA7"/>
    <path d="M35.2523 7.99951L35.0399 11.4629L34.8062 15.2237C35.9639 19.3776 35.401 23.8821 33.1493 27.6429C32.1509 29.3109 32.3846 31.4356 33.7016 32.8592C40.1594 26.0175 40.7648 15.5318 35.2523 7.99951Z" fill="#959FA7"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M24.1208 4.62124C24.8643 4.93995 25.8415 5.4499 26.8824 6.05545C25.5759 8.5308 20.4671 15.5106 6.58496 18.9528C21.5717 18.4428 28.8792 11.6436 31.2902 9.15761C32.1399 9.90127 32.8303 10.6449 33.2021 11.3355L33.8818 0.339844C33.6375 0.339844 26.7337 3.48449 24.1102 4.61061L24.1208 4.62124Z" fill="#959FA7"/>
    </g>
    <defs>
    <clipPath id="clip0_189_12682">
    <rect width="300" height="39" fill="white"/>
    </clipPath>
    </defs>
</svg>
)

const linkedinSvg = () => (
<svg id="linkedin" width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
    <title>LinkedIn_Icon</title>
    <g id="LinkedIn_Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M24,0 C37.2548347,0 48,10.7451653 48,24 C48,37.2548347 37.2548347,48 24,48 C10.7451653,48 0,37.2548347 0,24 C0,10.7451653 10.7451653,0 24,0 Z M19,18.4113262 L14,18.4113262 L14,34.0916864 L19,34.0916864 L19,18.4113262 Z M30.8783526,17.9090909 C28.3464371,17.9090909 27.217114,19.4285812 26.5838686,20.4914491 L26.5838686,20.4914491 L26.5838686,18.2761719 L21.8181818,18.2761719 C21.8364573,18.6951392 21.8442896,20.1508216 21.8461545,22.0166091 L21.8461959,23.9958905 C21.8461061,24.1108665 21.8460025,24.2264636 21.8458859,24.3425743 L21.8450356,25.0445429 C21.8445383,25.3977496 21.8439374,25.7536452 21.8432536,26.1093286 L21.8417817,26.8195533 C21.834716,30.0065558 21.8220558,33.019727 21.8189063,33.7442582 L21.8181818,33.9087033 L26.5835132,33.9087033 L26.5835132,25.1778362 C26.5835132,24.7091975 26.6158507,24.2452103 26.7412915,23.9110775 C27.0866981,22.9761258 27.8688379,22.0105518 29.1854339,22.0105518 C30.91069,22.0105518 31.5986603,23.4447645 31.5986603,25.5449172 L31.5986603,25.5449172 L31.5986603,33.9090909 L36.3636364,33.9090909 L36.3636364,24.9440983 C36.3636364,20.1433618 34.0133081,17.9090909 30.8783526,17.9090909 Z M16.3334298,11.8181818 C14.8129445,11.8181818 13.8181818,12.8983999 13.8181818,14.3176437 C13.8181818,15.7067518 14.784106,16.8181818 16.2750898,16.8181818 L16.3039283,16.8181818 C17.8542465,16.8181818 18.8181818,15.7067518 18.8181818,14.3176437 C18.7886804,12.8983999 17.8542465,11.8181818 16.3334298,11.8181818 Z" id="LinkedIn" fill="#6B7C93"></path>
    </g>
</svg>
)

const twitterSvg = () => (
    <svg id="twitter" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg"><path d="m1510 822c0-11.045696 8.9543-20 20-20s20 8.954304 20 20-8.9543 20-20 20-20-8.954304-20-20zm31.51515-6.939714c-.80303.365049-1.66467.612621-2.56998.723149.92438-.567891 1.63198-1.468644 1.96734-2.538664-.86709.525903-1.82421.907776-2.84403 1.114787-.81665-.893768-1.97826-1.450467-3.26667-1.450467-2.47181 0-4.47598 2.056102-4.47598 4.59056 0 .359453.03954.71053.1159 1.046246-3.71929-.191649-7.0173-2.019746-9.22462-4.797607-.38584.676992-.60534 1.465828-.60534 2.30788 0 1.593143.79076 2.998843 1.99055 3.821254-.73351-.025163-1.42339-.232174-2.02735-.576266v.057349c0 2.223942 1.54336 4.080018 3.58978 4.502452-.37494.103506-.77031.160854-1.17933.160854-.28902 0-.56852-.029369-.84256-.085328.56987 1.825318 2.22232 3.152675 4.18015 3.189067-1.53109 1.230874-3.46165 1.962398-5.55852 1.962398-.36128 0-.71715-.022384-1.06752-.062945 1.981 1.305011 4.33284 2.065904 6.86055 2.065904 8.23345 0 12.73399-6.996349 12.73399-13.063929l-.01501-.594443c.87936-.643453 1.64011-1.451893 2.23865-2.372251z" fill="#6b7c93" fillRule="evenodd" transform="translate(-1510 -802)"/></svg>
)


class Footer extends React.Component {
      render(styleData) {
        return (
            <>
            <SiteFooter styleData={styleData}>
                <SubFooterContent id="subFooter">
                    {contactSVG()}
                    <div>
                        <h2 className="margin-bottom-0">Get in touch!</h2>
                        <p>Our team is happy to answer any questions. Send us a message or call us directly at <a href="tel:408-461-7997">408-461-7997</a>. We’re looking forward to connecting with you.</p>
                    </div>
                    <ContactUsButton to="/company/contact-us">Contact Us</ContactUsButton>
                </SubFooterContent>
            </SiteFooter>
            <SiteFooter className="bg-dark-blue">
            <FooterDiv>
                <div className="column">
                    {logoSVG()}
                    <p>Questions? Call us at <a href="tel:408-461-7997">408.461.7997</a></p>
                    <div>
                        <a href="https://www.linkedin.com/company/ladd-partners" target="_blank">{linkedinSvg()}</a>
                        <a href="https://twitter.com/LaddPartners" target="_blank">{twitterSvg()}</a>
                    </div>
                    <p className="text-white"><small>©2024 Ladd Partners Inc. All rights reserved.</small></p>
                </div>
                <div className="column medium-only">
                    <a href="https://www.linkedin.com/company/ladd-partners" target="_blank">{linkedinSvg()}</a>
                    <a href="https://twitter.com/LaddPartners" target="_blank">{twitterSvg()}</a>
                </div>
                <div className="column">
                    <ul> 
                        <li><StyledMenuLink to="#" className="cursor-default" onClick={(evt) => { evt.preventDefault(); }} activeClassName="active">Services</StyledMenuLink></li>                   
                        <li className="kid"><StyledMenuLink to="/services/certinia" activeClassName="active">Certinia</StyledMenuLink></li>
                        <li className="kid"><StyledMenuLink to="/services/rootstock" activeClassName="active">Rootstock</StyledMenuLink></li>
                        <li className="kid"><StyledMenuLink to="/services/salesforce" activeClassName="active">Salesforce</StyledMenuLink></li>
                        <li className="kid"><StyledMenuLink to="/salesforceplatform" activeClassName="active">Salesforce Platform</StyledMenuLink></li>
                        <li className="kid"><StyledMenuLink to="/services/managed-services" activeClassName="active">Managed Services</StyledMenuLink></li>
                    </ul>
                </div>
                <div className="column">
                    <ul>
                        <li><StyledMenuLink to="#" className="cursor-default" onClick={(evt) => { evt.preventDefault(); }} activeClassName="active">Solutions</StyledMenuLink></li>
                        <li className="kid"><StyledMenuLink to="/solutions/cannabisessentials" activeClassName="active">Cannabis Essentials ERP</StyledMenuLink></li>
                        <li className="kid"><StyledMenuLink to="/solutions/quickconnect" activeClassName="active">Field Service QuickConnect</StyledMenuLink></li>
                        <li className="kid"><StyledMenuLink to="/solutions/supplychain" activeClassName="active">Supply Chain Education</StyledMenuLink></li>
                   </ul>
                </div>
                <div className="column">
                    <ul>
                        <li><StyledMenuLink to="#" className="cursor-default" onClick={(evt) => { evt.preventDefault(); }}  activeClassName="active">Company</StyledMenuLink></li>
                        <li className="kid"><StyledMenuLink to="/company/about" activeClassName="active">About Us</StyledMenuLink></li>
                        <li className="kid"><StyledMenuLink to="/company/case-studies" activeClassName="active">Case Studies</StyledMenuLink></li>
                        <li className="kid"><StyledMenuLink to="/company/team" activeClassName="active">Our Team</StyledMenuLink></li>
                        <li className="kid"><StyledMenuLink to="/company/careers" activeClassName="active">Careers</StyledMenuLink></li>
                        <li className="kid"><StyledMenuLink to="/company/contact-us" activeClassName="active">Contact Us</StyledMenuLink></li>
                        <li className="kid"><StyledMenuLink to="/company/privacy" activeClassName="active">Privacy Statement</StyledMenuLink></li>
                   </ul>
                </div>
            </FooterDiv>
            </SiteFooter>
            </>
        )
      }
}

export default Footer